<template>

  <el-card class="box-card" style="width:500px" shadow="never">
    <template #header>
      <div class="card-header">
        <span>Вход  {{authUrl}}</span>
      </div>
    </template>
    <el-form label-position="right" label-width="100px" :model="form">
      <el-form-item label="Логин">
        <el-input v-model="form.login" placeholder="Логин"></el-input>
      </el-form-item>
      <el-form-item label="Пароль">
        <el-input type="password" v-model="form.password" placeholder="Пароль"></el-input>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" @click="loginTo">Войти</el-button>
      </el-form-item>
    </el-form>
  </el-card>

</template>

<script>

import store from "../store";
import router from '../router/index.js'

export default {
  name: 'LoginUser',
  data: function () {
    return {
      form: {
        login: "",
        password: ""
      }
    }
  },
  computed: {
    authUrl: function(){
      return store.getters.authUrl
    }
  },
  mounted() {
    if (store.getters.refreshToken!==""){
      router.push({name: 'Processes'})
    }
  },
  methods: {
    loginTo() {
      store.commit('login', {
        login: this.form.login,
        password: this.form.password,
        callback: function (success) {
          if (success) {
            router.push({name: 'Processes'})
          }
        }
      })
    }
  },
}
</script>

<style scoped>

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
